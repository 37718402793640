
<template>
  <div>
    <b-card no-body class="m-0">
      <!-- Table Top -->
      <b-row class="d-flex justify-content-between">
        <!-- Per Page -->
        <b-col class="mt-1 d-flex">
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :clearable="false"
            v-model="per_page"
            class="per-page-selector ml-1"
            style="width: 100px"
            :options="['5', '10', '20', '50', '100']"
            @input="changePagination()"
          />
        </b-col>
        <!-- Search -->
        <b-col class="mt-1 d-flex justify-content-end">
          <b-form-input
            placeholder="Search..."
            v-model="search"
            @input="handleSearch()"
            class="mr-1"
            style="width: 200px"
          />
        </b-col>
      </b-row>
      <div class="m-2">
        <section id="card-images" class="container1">
          <b-row
            md="12"
            style="align-item: center; justify-content: center; display: flex"
          >
            <b-table
              :busy="isLoading"
              ref="refUserListTable"
              class="position-relative"
              :items="exercise"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="No matching records found"
              :sort-desc.sync="isSortDirDesc"
              @sort-changed="onSortChanged"
            >
              <template #table-busy>
                <div class="text-center text-secondary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
              <template #cell(id)="data">
                <span class="text-primary font-weight-bolder">
                  {{ data.index + 1 }}
                </span>
              </template>

              <template #row-details="data">
                <b-table
                  v-if="
                    data.item.sub_exercises &&
                    data.item.sub_exercises.length > 0
                  "
                  :items="data.item.sub_exercises"
                  :fields="['No','name', 'comment', 'sets', 'reps', 'time']"
                  class="ml-0"
                  
                  >
                  <!-- thead-tr-class="d-none" -->
                <template #cell(No)="data">
                  {{ data.index + 1 }}
                </template>
                  <template #cell(name)="data">
                    <template v-if="data.item.video && data.item.video.thumbnail ">

                      <b-img
                        height="60px"
                        width="60px"
                        class="mr-1 titleName"
                        :src="data.item.video.thumbnail"
                      ></b-img>
                    </template>
                    <template v-else>
                      <span>
                        <img
                          src="../../../../../../public/no-image.png"
                          style="height: 40px; width: 40px"
                          alt=""
                        />
                      </span>
                    </template>
                    <span>{{ data.item.name }}</span>
                  </template>
                </b-table>
              </template>
              <template #cell(name)="data">
                <div class="d-flex align-items-center">
                  <span class="mr-1"> </span>
                  <span class="mr-1">
                    <span class="mr-1">
                      {{ data.item.name }}
                    </span>
                  </span>
                </div>
              </template>
              <template #cell(Action)="data">
                <div class="d-flex align-items-center">
                  <feather-icon
                    icon="EditIcon"
                    size="22"
                    class="cursor-pointer mr-1"
                    @click="handleEditForm(data.item)"
                  />
                  <feather-icon
                    icon="Trash2Icon"
                    size="22"
                    class="m-1"
                    @click="removeRow(data.item.id)"
                    style="cursor: pointer"
                  />
                  <feather-icon
                    icon="EyeIcon"
                    size="22"
                    class="m-1"
                    @click="data.toggleDetails"
                    style="cursor: pointer"
                  />
                </div>
              </template>
            </b-table>
            <div class="w-100 d-flex justify-content-between p-1">
              <!-- Pagination -->
              <div>
                <span class="text-nowrap">
                  Showing {{ paginationStart }} to {{ paginationEnd }} of
                  {{ paginationTotal }}</span
                >
              </div>
              <b-pagination
                :total-rows="paginationTotal"
                pills
                :per-page="per_page"
                v-model="paginationValue"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="changePagination()"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
            <b-col class="col-md-12 d-flex d-flex justify-content-end">
              <b-button variant="primary" @click="addExercise()" class="mt-2"
                >Add Exercise
              </b-button>
            </b-col>

            <b-modal
              hide-footer
              top
              no-close-on-backdrop
              size="lg"
              v-model="videoModal"
            >
              <div>
                <div
                  class="d-flex justify-content-center align-items-center flex-column"
                >
                  <h3 class="text-large">Select Video</h3>
                  <h6 class="text-muted">
                    Select a perticular Exercises Video.
                  </h6>
                </div>
                <validation-observer ref="videoRules">
                  <b-form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="12" class="mt-2">
                        <b-form-group>
                          <b-form-input
                            v-model="videoSearch"
                            placeholder="Search Video"
                            @input="handleVideoSearch()"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <h5>Select Video</h5>
                      </b-col>

                      <div
                        v-if="isLoadingCategory"
                        style="height: 200px"
                        class="w-100 d-flex justify-content-center align-items-center"
                      >
                        <b-spinner label="Loading..."></b-spinner>
                      </div>
                      <div
                        v-else
                        class="w-100"
                        style="max-height: 600px; overflow-y: auto"
                      >
                        <b-col
                          md="12"
                          class="mt-1"
                          v-for="(item, index) in exerciseVideo"
                          :key="index"
                        >
                          <div class="d-flex justify-content-between w-100">
                            <div>
                              <b-media no-body>
                                <b-media-aside>
                                  <b-avatar
                                    rounded
                                    size="42"
                                    variant="light-danger"
                                  >
                                    <feather-icon size="18" icon="WindIcon" />
                                  </b-avatar>
                                </b-media-aside>
                                <b-media-body>
                                  <h6 class="transaction-title">
                                    {{ item.title }}
                                  </h6>
                                  <!-- <small>Scales with any business</small> -->
                                </b-media-body>
                              </b-media>
                            </div>
                            <input
                              type="radio"
                              v-model="selectedVideo"
                              name="some-radios"
                              class="radio-right ml-5 cursor-pointer"
                              style="height: 20px; width: 20px"
                              :value="item"
                            />
                          </div>
                        </b-col>
                      </div>
                      <b-col md="12" class="d-flex mr-2 justify-content-end">
                        <b-button
                          variant="primary"
                          style="font-family: Public Sans, sans sarif"
                          class="mr-1"
                          @click="handleVideoSelect()"
                          :disabled="flag"
                        >
                          {{ ifEdit == true ? "Update" : "Save" }}
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </div>
            </b-modal>
          </b-row>
        </section>
        <div class="mt-5" v-if="addWorkout" id="nutritionForm">
          <validation-observer ref="simpleRules">
            <b-form @submit.prevent="handleAddRow()" ref="myForm">
              <b-col md="12" class="mr-1">
                <b-form-group>
                  <label for="input">Exercise Name</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Exercise"
                  >
                    <b-form-input
                      id="input"
                      maxlength="100"
                      v-model="mainExercise"
                      placeholder="Exercise Name"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-row v-for="(item, index) in sub_exercises" :key="index" class="mt-3">
                <b-col md="10">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Sub Exercise"
                    >
                      <label
                        for="input"
                        style="font-family: Public Sans, sans sarif"
                        >Sub Exercise</label
                      >
                      <b-form-input
                        maxlength="100"
                        label="name"
                        v-model="item.name"
                        placeholder="Sub Exercise Name"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <div>
                    <span>
                      <img
                        height="40px"
                        width="40px"
                        style="cursor: pointer"
                        class="mt-2 mr-1"
                        src="../../../../../../public/plusicon.svg"
                        @click="addNewForm(index)"
                        alt=""
                      />
                    </span>
                    <span v-if="sub_exercises.length > 1">
                      <img
                        height="40px"
                        width="40px"
                        style="cursor: pointer"
                        class="mt-2"
                        src="../../../../../../public/cancelIcon.svg"
                        @click="removeForm(index)"
                        alt=""
                      />
                    </span>
                  </div>
                </b-col>
                <!-- <b-col md="4" v-if="!item.time"> -->
                    <b-col md="4" v-if="(!item.time)|| item.time== '00:00' || item.sets">

                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Sets"
                    >
                      <label
                        for="input"
                        style="font-family: Public Sans, sans sarif"
                        >Sets</label
                      >
                      <b-form-input
                        id="input"
                        maxlength="10"
                        v-model="item.sets"
                        placeholder="Sets"
                        type="number" 

                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4" v-if="!item.time || item.time== '00:00' || item.reps">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Reps"
                    >
                      <label
                        for="input"
                        style="font-family: Public Sans, sans sarif"
                        >Reps</label
                      >
                      <b-form-input
                        id="input"
                        v-model="item.reps"
                        maxlength="10"
                        type="number" 

                        placeholder="Reps"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- <b-col md="4" v-if="!item.reps && !item.sets"> -->
                    <b-col md="4" v-if="(!item.reps && !item.sets) || (item.time && item.time !='00:00')">

                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Time"
                    >
                      <label
                        for="input"
                        style="font-family: Public Sans, sans sarif"
                        >Time (In minutes)</label
                      >
                      <!-- <b-form-input
                        id="input"
                        v-model="item.time"
                        type="number" 
                        placeholder="Time"
                      ></b-form-input>
                       -->
                          <!-- <flat-pickr
                            v-model="item.time"
                            style="background-color: transparent"
                            class="form-control "
                            placeholder="HH:MM"
                            :config='{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: "H:i",
                              time_24hr: true 
                            }'
                          /> -->
                          <b-form-input
                          id="input"
                          maxlength="10"
                          v-model="item.time"
                          placeholder="time"
                        ></b-form-input>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label
                      for="input"
                      style="font-family: Public Sans, sans sarif"
                      >Comment</label
                    >
                    <b-form-input
                      id="input"
                      v-model="item.comment"
                      placeholder="Comment"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label for="input">Exercise Video</label>
                    <validation-provider
                      #default="{ errors }"
                      :rules="
                        item.video && item.video.length > 0
                          ? 'required'
                          : ''
                      "
                      name="Video"
                    >
                      <!-- {{ item.video_url.url }} -->
                      <div class="selectVideo" @click="openVideoModal(item)">
                        <!-- {{item.video_url.title}} -->
                        {{
                          item.video ? item.video.title : "Select Video"
                        }}
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-col md="12" class="d-flex justify-content-start mt-2">
                <b-button
                  variant="primary"
                  style="font-family: Public Sans, sans sarif"
                  class="mr-1"
                  @click="handleAddRow()"
                  :disabled="flag"
                >
                  {{ ifEdit == true ? "Update" : "Save" }}
                </b-button>
                <b-button
                  type="reset"
                  variant="primary"
                  style="font-family: Public Sans, sans sarif"
                  @click="Close()"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-form>
          </validation-observer>
          <img
            id="myImage"
            src="placeholder.jpg"
            alt="Placeholder Image"
            class="invisible"
          />
        </div>
      </div>
    </b-card>
  </div>
</template>
  <script>
import {
  BRow,
  BCol,
  BCardText,
  BCardTitle,
  BImg,
  BButton,
  BCard,
  BForm,
  BTable,
  BPagination,
  BFormFile,
  BFormInput,
  BFormGroup,
  BBadge,
  BSpinner,
  BFormTimepicker,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BMedia,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import axios from "@/component/axios";
import { required, integer } from "@validations";
// import { number } from "vee-validate/dist/rules";
import _ from "lodash";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    BMediaBody,
    flatPickr,
    BMediaAside,
    BAvatar,
    BMedia,
    BFormTimepicker,
    BRow,
    BCol,
    BPagination,
    vSelect,
    BCardText,
    BImg,
    BCardTitle,
    BButton,
    BCard,
    BForm,
    BTable,
    BFormFile,
    BFormInput,
    BFormGroup,
    BBadge,
    ValidationProvider,
    BSpinner,
    ValidationObserver,
    required,
    integer,
  },
  data() {
    return {
      searchTerm: { name: "Title", value: "title" },
      videoSearch: "",
      videoSearchTerm: { name: "Title", value: "title" },
      tableColumns: [
        { key: "id", label: "No" },
        { key: "name", label: "Exercise", sortable: true },
        // { key: "comment", label: "Comment" },
        // { key: "sets", label: "Set" },
        // { key: "reps", label: "Reps" },
        // { key: "time", label: "Time" },
        { key: "Action", label: "Actions" },
      ],
      isSortDirDesc: true,
      addWorkout: false,
      required: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      clickedRowData: null,
      isSideDataLoading: false,
      image: "",
      videoModal: false,
      exercise: [],
      id: 0,
      isLoading: false,
      selectedItem: {},
      ifEdit: false,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      paginationStart: 0,
      paginationEnd: 0,
      selectedId: null,
      selectedVideo: "",
      flag: false,
      allVideo: [],
      columnFilters: {},
      params: {},
      isLoadingCategory: false,
      exerciseVideo: [],
      search: "",
      Selected: null,
      mainExercise: "",
      sub_exercises: [
        {
          name: "",
          sets: "",
          reps: "",
          time: "",
          comment: "",
          video: "",
        },
      ],
      allVideo:[],
    };
  },
  mounted() {
    this.getExercise();
    this.getVideo();
  },
  methods: {
    handleSearch: _.debounce(function () {
      this.getExercise();
    }, 1000),
    handleVideoSearch: _.debounce(function () {
      this.getSearchVideo();
    }, 1000),
    getSearchVideo(){
      let regex = new RegExp(this.videoSearch, 'i');
      this.exerciseVideo = this.allVideo.filter(item=> regex.test(item.title))
    },
    handleVideoSelect() {
      this.$refs.videoRules.validate().then(async (success) => {
        if (success) {
          if (this.selectedVideo) {
            this.selectedItem.video = this.selectedVideo;
            this.videoModal = false;
          } else {
            this.$swal({
              title: "Warning",
              timer: 5000,
              text: "Please fill all field",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        } else {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    async getVideo() {
      this.isLoadingCategory = true;
      await axios({
        method: "GET",
        url: `${this.baseApi}exercise/get-videos`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((response) => {
          this.allVideo = response.data.data;
          this.exerciseVideo = response.data.data;
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isLoadingCategory = false;
        });
    },
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      this.getExercise();
    },

    handleAddRow(id) {
      this.$refs.simpleRules.validate().then((success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.handleForm(id);
        }
      });
    },
    changePagination() {
      this.getExercise();
    },
    async handleEditForm(item) {
      this.addWorkout = true;
      this.ifEdit = true;
      this.mainExercise = item.name;
      this.sub_exercises = item.sub_exercises.map((item) => {
        return {
          name: item.name,
          sets: item.sets,
          reps: item.reps,
          time: item.time,
          comment: item.comment,
          video: item.video,
          id:item.id
        };
      });
      this.id = item.id;
      setTimeout(() => {
        const formElement = this.$refs.myForm;
        if (formElement) {
          formElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 100);
    },
    handleForm() {
      this.flag=true
      const customerData = {
        name: this.mainExercise,
        sub_exercises: this.sub_exercises.map((subExercise) => {
          return {
            name: subExercise.name,
            id: subExercise.id ?subExercise.id:null,
            sets: subExercise.sets,
            reps: subExercise.reps,
            time: subExercise.time == '00:00'?'':subExercise.time,
            comment: subExercise.comment,
            video_id:
              subExercise && subExercise.video
                ? subExercise.video.id
                : "",
          };
        }),
      };
      const requestOptions = {
        method: `${this.ifEdit ? "put" : "post"}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: this.ifEdit
          ? `${this.baseApi}exercise/update/${this.id}`
          : `${this.baseApi}exercise/add`,
        data: JSON.stringify(customerData),
      };
      axios(requestOptions)
        .then(() => {
          this.$swal({
            title: "Submitted",
            text: this.ifEdit
              ? "Sub Exercise Updated Successfully"
              : " Sub Exercise Added Successfully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getExercise();
          this.addWorkout = false;
          this.$refs.myForm.reset();
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with an error status code
            const errorDetails = error.response?.data?.errors;
            const errorMessage = errorDetails ?Object.values(errorDetails):'';
            let message =''
            errorMessage.map((item)=>{
              let arr=item[0].split('.')
              let last = arr.pop()
              if(arr.length>1 && !isNaN(arr[1])){
                message += `In day ${arr[1]+1} ${last ? last:arr.pop()} \n` 
              }
              })
            this.$swal({
              title: "Error!",
              html: message? message:errorMessage,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else if (error.request) {
            // The request was made but no response was received
            console.error("No response received");
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error setting up the request", error.message);
          }
        })
        .finally(()=>{
          this.flag =false
        })
    },
    // checkImage(item) {
    //   let testImage = new Image();
    //   testImage.onload = () => {
    //     // If the image exists, update the image URL in the data array
    //     item.image = testImage.src;
    //   };
    //   testImage.onerror = () => {
    //     // If the image URL is invalid, set a default value
    //     item.image = '';
    //   };
    //   if (item.image) {
    //     testImage.src = item.image;
    //   }
    // },
    async getExercise() {
      this.isLoading = true;
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      }
      if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}exercise/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
        // timeout: 1000,
      })
        .then((json) => {
          this.exercise = json?.data.main_exercise.data;
          this.paginationTotal = json?.data.main_exercise.total;
          this.paginationStart = json?.data.main_exercise.current_page;
          this.paginationEnd = json?.data.main_exercise.per_page;
          this.isLoading = false;
          this.exercise.map((item) => {
            let img = document.getElementById("myImage");
            let testImage = new Image();
            // Set up event  listeners for the test image
            testImage.onload = function () {
              // If the image exists, update the source of the main image
              if (item.image && img) {
                img.src = item.image;
              }
            };
            testImage.onerror = function () {
              item.image = "";
            };
            if (item.image) {
              testImage.src = item.image;
            }
          });
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isLoading = false;
        });
    },
    openVideoModal(item) {
      this.selectedItem = item;
      this.videoModal = true;
      this.selectVideo = "";
      this.videoSearch = ''
      this.exerciseVideo = this.allVideo.slice()
      //   this.selectedItem = data;
    },

    async removeRow(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          if (result.value) {
            await axios({
              method: "Delete",
              url: `${this.baseApi}exercise/delete/${id}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
            })
              .then(() => {
                const index = this.exercise.findIndex((item) => item.id === id);
                if (index !== -1) {
                  this.exercise.splice(index, 1);
                  this.$swal({
                    title: "Deleted",
                    text: "Client deleted successfully",
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                  this.getExercise();
                  this.$forceUpdate();
                }
              })
              .catch((error) => {
                console.error("Error deleting user:", error);
              });
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {});
    },
    addExercise() {
      this.ifEdit = false;
      this.addWorkout = true;
      this.mainExercise = "";
      this.sub_exercises=[ {
        name : "",
        sets : "",
        reps : "",
        time : "",
        comment : "",
        video_url : ""
      }];
      this.$forceUpdate()
      setTimeout(() => {
        const formElement = this.$refs.myForm;
        if (formElement) {
          formElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 100);
    },
    Close() {
      this.addWorkout = false;
      this.videoModal = false;
      this.mainExercise = "";
      this.sub_exercises.map((item) => {
        item.name = "";
        item.sets = "";
        item.reps = "";
        item.time = "";
        item.comment = "";
        item.video_url = "";
      });
    },
    addNewForm() {
      this.sub_exercises.push({
        name: "",
        time: "",
        sets: "",
        reps: "",
        comment: "",
        video_url: "",
      });

      setTimeout(() => {
        const lastIndex = this.sub_exercises.length - 1;

        const formElement = this.$refs[`myForm${lastIndex}`];
        if (formElement) {
          formElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 100);
    },
    removeForm(index) {
      this.sub_exercises.splice(index, 1);
    },
  },
};
// .custom-no-head thead {
//   display: none;
// }
</script>
  <style lang="scss">
.text-danger {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
.selectVideo {
  height: 37px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
  align-items: center;
  display: flex;
  padding-left: 10px;
  background-color: rgb(219, 218, 222);
  cursor: pointer;
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  
   
  <style scoped>
.titleName {
  font-family: "Public Sans", sans-serif;
}

.tbody-tr-class td{
  width:20px !important;
}
</style>